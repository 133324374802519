import React from 'react';
import {LinkedinOutlined, GithubOutlined, TwitterOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import './Header.css';

const header = (props) => {
    return (
        <div className="main-header-row">
            <div className="header-row-1 header-div">
                <div className="header-title">Sunny Setia</div>
                <div className="header-subtitle"> Software Engineer</div>
            </div>
            <div className="header-row-2 header-logos">
                <span>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sunny_setia_"><TwitterOutlined/></a>
                </span>
                <span>
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/sunnysetia93"><GithubOutlined/></a>
                </span>
                <span>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/sunnysetia93/"><LinkedinOutlined/></a>
                </span>
            </div>
        </div>

    )
}

export default header;