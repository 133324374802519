import React from 'react';
import {SendOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import {Input, Select, Button, Row, Col, Switch} from 'antd';
import './DevRequest.css'
import dev_mode_img from "../../../icons/dev_mode.png";

const {Option} = Select;


const devRequest = (props) => {
    const selectedMethod = props.methods[props.selectedMethodIndex];
    const selectedPaths = selectedMethod.pathValues;
    const renderPaths = selectedPaths.map((i) => {
        const pathData = i;
        return <Option className="select-option" key={pathData.value}
                       value={pathData.value}>{pathData.path}</Option>
    });
    const selectedPathData = selectedPaths[props.selectedPathIndex];
    return (
        <Row className="dev-request-main-row main-body">
            <Col lg={3} sm={6} xs={6} md={6} className="dev-col dev-icon-col">
                <img src={dev_mode_img} alt="logo" className="animated pulse"/>
                <label className="icon-label">Dev Mode</label>
                <Switch onChange={props.devModeChange} checked={props.prodModeFlag}/>
            </Col>
            <Col xs={18} sm={18} md={18} lg={6} className="dev-col col-1-row method-col animated fadeIn faster">
                <label className="lbl">Method</label>
                <Input.Group compact className="dev-input-group-col">
                    <Select className="input-select"
                            style={{ width: "95%"}}
                            defaultValue={selectedMethod.label}
                            dropdownStyle={{backgroundColor: "var(--primary-color)"}}
                            onChange={props.methodChange}
                    >
                        <Option className="select-option" value={0}>GET</Option>
                        <Option className="select-option" value={1}>POST</Option>
                    </Select>
                </Input.Group>
            </Col>
            <Col sm={24} md={24} xs={24} lg={10} className="dev-col col-1-row url-col">
                <label className="lbl animated fadeIn faster">URL / Path</label>
                <Input.Group compact className="input-group-col-2 animated fadeIn">
                    <Input className="input-url" addonBefore="http://localhost:8080/api"
                           defaultValue=""
                           disabled
                           style={{minWidth: "40%"}}
                    />
                    <Select className="input-select"
                            value={selectedPathData.path}
                            style={{ width: "40%"}}
                            dropdownStyle={{backgroundColor: "var(--primary-color)"}}
                            onChange={props.pathChange}
                    >
                        {renderPaths}
                    </Select>
                </Input.Group>
            </Col>
            <Col sm={24} md={24} xs={24} lg={5} className="dev-col send-div animated fadeIn faster">
                <Button
                    className="send-button"
                    type="primary"
                    shape="round"
                    icon={<SendOutlined/>}
                    onClick={props.clickSend}
                >
                    Send
                </Button>
            </Col>
        </Row>

    )
}

export default devRequest;
