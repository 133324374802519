import React, {Component} from 'react';
import AboutMe from './AboutMe/AboutMe';
import Timeline from "./Timeline/Timeline";
import Contact from "./Contact/Contact";
import Response from '../Response/Response';
import './MainBody.css';

class MainBody extends Component {

    render() {
        let bodyComponent = null;
        if (!this.props.prodModeFlag) {
            if (this.props.selectedMethod === 0) {
                switch (this.props.selectedPathIndex) {
                    case 0:
                        bodyComponent = <Response content={1} prodBodyClickHandler={this.props.prodBodyClickHandler} />
                        break;
                    case 1:
                        bodyComponent =<Response content={2} prodBodyClickHandler={this.props.prodBodyClickHandler} />
                        break;
                    default:
                        bodyComponent = <Response content={1} prodBodyClickHandler={this.props.prodBodyClickHandler} />
                        break;
                }
            } else {
                switch (this.props.selectedPathIndex) {
                    case 0:
                        bodyComponent = <Contact loading={this.props.loading} enterLoading={this.props.enterLoading}/>
                        break;
                    default:
                        bodyComponent = <Contact loading={this.props.loading} enterLoading={this.props.enterLoading}/>
                        break;
                }
            }
        } else {
            switch (this.props.prodBodySelect) {
                case 1:
                    bodyComponent = <AboutMe prodBodyClickHandler={this.props.prodBodyClickHandler}/>
                    break;
                case 2:
                    bodyComponent = <Timeline/>
                    break;
                case 3:
                    bodyComponent = <Contact loading={this.props.loading} enterLoading={this.props.enterLoading}/>
                    break;
                default:
                    break;
            }
        }
        return (
            <div>
                {bodyComponent}
                {this.props.prodBodySelect ? <div className="footer">Copyright © 2020 | Sunny Setia </div> : null}
            </div>
        )
    }
}

export default MainBody