import React from 'react';
import {Form, Input, Button, InputNumber, notification} from 'antd';
import contact_img from '../../../images/img-01.png';
import mail_img from '../../../icons/mail_img.png';
import './Contact.css';

const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 16
    },
};
const validateMessages = {
    required: 'This field cannot be left blank.',
    types: {
        email: 'Please enter a valid Email',
        number: 'Please enter a valid number'
    },
    len: 'Contact Number should be of 10 digits'
};

const openNotification = () => {
    notification.open({
        message: 'Message Sent Successfully',
        description:
            'I\'ll get back to you shortly.',
        style: {
            width: 300,
            marginLeft: 335 - 250,
        },
        duration:2
    });
};

const Contact = (props) => {
    const [form] = Form.useForm();
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const onFinish = values => {
        console.log(values);
        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({"form-name": "contact", ...values})
        })
            .then(() => {
                console.log("Success!");
                form.resetFields();
                openNotification();
            })
            .catch(error => console.log("error submitting form"));

        // e.preventDefault();
    };

    let mailId = <a href="/">sunnysetia93@gmail.com</a>;
    return (
        <div className="main-body contact-body-row animated bounceInUp">
            <div className="contact-col contact-right-border">
                <img alt="contact_image" src={contact_img}/>
            </div>
            <div className="contact-col">
                <p>Drop me a message</p>
                <div className="contact-form">
                    <Form {...layout} form={form} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                        <input type="hidden" name="form-name" value="contact"/>
                        <Form.Item
                            name='name'
                            label="Name"
                            rules={[
                                {
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="Enter full name"/>
                        </Form.Item>
                        <Form.Item
                            name='email'
                            label="Email"
                            rules={[
                                {
                                    type: 'email',
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="person@xyz.com"/>
                        </Form.Item>
                        <Form.Item
                            name="contactNumber"
                            label="Contact No."
                            rules={[
                                {
                                    type: 'number',
                                    message: 'Please enter a valid number'
                                },
                                {
                                    pattern: /^\d{10}$/,
                                    message: "Contact Number should be of 10 digits"
                                }
                            ]}
                        >
                            <InputNumber maxLength={10} minLength={10} placeholder="Enter a 10 digit number"/>
                        </Form.Item>
                        <Form.Item
                            name='message'
                            label="Message"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input.TextArea/>
                        </Form.Item>
                        <Form.Item wrapperCol={{...layout.wrapperCol, offset: 4}}>
                            <Button className="submit-btn" type="primary" htmlType="submit" loading={props.loading}
                                    onClick={props.enterLoading}>
                                Submit
                            </Button>

                        </Form.Item>
                    </Form>
                    <div className="mail-div">or just <img alt="email" className="mail-img" src={mail_img}/> me at {mailId}</div>
                </div>

            </div>
        </div>

    )
};

export default Contact;
