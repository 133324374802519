import React, {Component} from 'react';
import DevRequest from './DevRequest/DevRequest';
import LiveRequest from './LiveRequest/LiveRequest';
import 'antd/dist/antd.css';
import {Switch} from 'antd';
import './Request.css';
import live_mode_img from '../../icons/live_mode_new.png';

class Request extends Component {

    render() {

        if (this.props.prodModeFlag) {
            return (
                <div className="request-main-row main-body">
                    <div className="request-col-1-2 icon-col">
                        <img src={live_mode_img} alt="logo" className="animated rotateIn"/>
                        <label className="icon-label">Live</label>
                        <Switch onChange={this.props.devModeChange} checked={this.props.prodModeFlag}/>
                    </div>
                    <LiveRequest prodBodyClickHandler={this.props.prodBodyClickHandler}/>
                </div>
            )
        } else {
            return <DevRequest
                methods={this.props.methods}
                selectedMethodIndex={this.props.selectedMethodIndex}
                selectedPathIndex={this.props.selectedPathIndex}
                methodChange={this.props.methodChange}
                pathChange={this.props.pathChange}
                clickSend={this.props.clickSend}
                devModeChange={this.props.devModeChange}
                prodModeFlag={this.props.prodModeFlag}
            />

        }

    }
}

export default Request