import React, {Component} from 'react';
import Header from './components/Header/Header';
import Request from './components/Request/Request';
import MainBody from './components/MainBody/MainBody';
import './App.css';
import moonRegularIcon from './icons/moon-regular.svg';
import sunRegularIcon from './icons/sun-regular.png';

class App extends Component {
    state = {
        lightTheme: localStorage.getItem("theme") === "light" ? true : false,
        themeValue: localStorage.getItem("theme"),
        methods: [
            {
                value: 0,
                label: 'GET',
                pathValues: [
                    {
                        value: 0,
                        path: '/about'
                    },
                    {
                        value: 1,
                        path: '/profile'
                    }
                ]
            },
            {
                value: 1,
                label: 'POST',
                pathValues: [{
                    value: 0,
                    path: '/contactMe'
                }]
            }
        ],
        selectedMethodIndex: 0,
        selectedPathIndex: 0,
        clickedSendButtonFlag: false,
        myRef: null,
        prodModeFlag: true,
        prodBodySelect: 1,
        isLoading: true,
        loading: false
    }

    componentDidMount() {
        document
            .getElementsByTagName("HTML")[0]
            .setAttribute("data-theme", localStorage.getItem("theme"));
        this.setState({
            isLoading: false
        })
        const script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/github-cards/latest/widget.js";
        document.body.appendChild(script);
    }

    changeMethodHandler = (index) => {
        this.setState({
            selectedMethodIndex: index,
            selectedPathIndex: 0,
            clickedSendButtonFlag: false
        }, () => {
        })
    }

    changePathHandler = (index) => {
        this.setState({
            selectedPathIndex: index,
            clickedSendButtonFlag: false
        })
    }

    clickSendButtonHandler = () => {
        this.setState({
            clickedSendButtonFlag: true
        })
    }

    devModeChangeHandler = (newFlag) => {
        this.setState({
            prodModeFlag: newFlag,
            clickedSendButtonFlag: false,
            prodBodySelect: 1
        })
    }
    prodBodyClickHandler = (value) => {
        this.setState({
            prodModeFlag: true,
            prodBodySelect: value
        })
    }

    enterLoading = () => {
        this.setState({loading: true});
        setTimeout(() => {
            this.setState({loading: false});
        }, 1000);
    };


    toggleTheme = () => {
        const {lightTheme} = this.state;
        if (lightTheme === false) {
            localStorage.setItem("theme", "light");
            document
                .getElementsByTagName("HTML")[0]
                .setAttribute("data-theme", localStorage.getItem("theme"));
            this.setState({
                lightTheme: true
            });
        } else {
            localStorage.setItem("theme", "dark");
            document
                .getElementsByTagName("HTML")[0]
                .setAttribute("data-theme", localStorage.getItem("theme"));
            this.setState({
                lightTheme: false
            });
        }
    }

    render() {
        let svgTheme = "svg-dark animated rotateIn slow";
        if (this.state.lightTheme) {
            console.log("light theme activated");
            svgTheme = "svg-light animated rotateIn slow";
        }
        const themeIcon = this.state.lightTheme ? moonRegularIcon : sunRegularIcon;

        const mainBody = (this.state.clickedSendButtonFlag || this.state.prodModeFlag) ? <MainBody
            selectedMethod={this.state.selectedMethodIndex}
            selectedPathIndex={this.state.selectedPathIndex}
            reference={this.state.myRef}
            prodModeFlag={this.state.prodModeFlag}
            prodBodySelect={this.state.prodBodySelect}
            prodBodyClickHandler={this.prodBodyClickHandler}
            loading={this.state.loading}
            enterLoading={this.enterLoading}
        /> : null;

        return (
            <React.Fragment>
                <div className="theme-div">
                    <img alt="theme_icon" src={themeIcon} onClick={this.toggleTheme} className={svgTheme}/>
                </div>
                <div className="App animated fadeIn slow">
                    <Header lightTheme={this.state.lightTheme}/>
                    <Request
                        methods={this.state.methods}
                        selectedMethodIndex={this.state.selectedMethodIndex}
                        selectedPathIndex={this.state.selectedPathIndex}
                        methodChange={this.changeMethodHandler}
                        pathChange={this.changePathHandler}
                        clickSend={this.clickSendButtonHandler}
                        devModeChange={this.devModeChangeHandler}
                        prodModeFlag={this.state.prodModeFlag}
                        prodBodyClickHandler={this.prodBodyClickHandler}
                    />
                    {mainBody}
                </div>
            </React.Fragment>


        )
    }
}

export default App;
