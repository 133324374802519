import React from 'react';
import {Progress} from 'antd';
import './ProgessBar.css';

const progessBar = (props) => {
    return (
        <div className="progess-bar-div">
            <label>{props.labelText}</label>
            <Progress percent={props.percent} showInfo={props.showInfoFlag} strokeColor={"#FD6864"}/>
        </div>
    )
};

export default progessBar;