import React from 'react';
import ProgessBar from './ProgessBar/ProgessBar';
import './Timeline.css'

const timeline = () => {
  return (
    <div className="main-body timeline-body-row animated bounceInUp">
      <div className="timeline-col-1">
        <p>Tech Skills</p>
        <ProgessBar labelText={"Node.js | JavaScript"} percent={"92"} showInfoFlag={false}/>
        <ProgessBar labelText={"Vue.js"} percent={"85"} showInfoFlag={false}/>
        <ProgessBar labelText={"React.js"} percent={"81"} showInfoFlag={false}/>
        <ProgessBar labelText={"GraphQL"} percent={"80"} showInfoFlag={false}/>
        <ProgessBar labelText={"Java"} percent={"70"} showInfoFlag={false}/>
        <ProgessBar labelText={"Python - Django"} percent={"70"} showInfoFlag={false}/>
        <ProgessBar labelText={"SQL Databases - PostgresSQL | MySQL"} percent={"82"} showInfoFlag={false}/>
        <ProgessBar labelText={"NoSQL Databases - MongoDB"} percent={"80"} showInfoFlag={false}/>
        <ProgessBar labelText={"ElasticSearch | Redis"} percent={"75"} showInfoFlag={false}/>
        <ProgessBar labelText={"Internet Of Things"} percent={"65"} showInfoFlag={false}/>
        <ProgessBar labelText={"Cloud Services - AWS | GCP"} percent={"75"} showInfoFlag={false}/>
        <ProgessBar labelText={"Dev-Ops"} percent={"70"} showInfoFlag={false}/>
        <div>
          <p style={{marginTop:"3em", marginBottom:"10px"}}>Projects</p>
            <hr style={{width:"50%"}}/>
            <ul className="projects-section" style={{marginTop:"2em"}}>
            <li><a className={"projects-section-list-item"} rel="noopener noreferrer" target={"_blank"} href={"https://remusic.netlify.app/"}>ReMusic <span role="img" aria-label="Pin">🔗</span></a></li>
            <li><a className={"projects-section-list-item"} rel="noopener noreferrer" target={"_blank"} href={"http://143.110.189.128/login"}>Stay Social <span role="img" aria-label="Pin">🔗</span></a></li>
            <li><a className={"projects-section-list-item"} rel="noopener noreferrer" target={"_blank"} href={"https://github.com/sunnysetia93/spotify-clone"}>Spotify Clone <span role="img" aria-label="Pin">🔗</span></a></li>
            <li><a className={"projects-section-list-item"} rel="noopener noreferrer" target={"_blank"} href={"https://www.youtube.com/watch?v=ifcoaz6oWJA"}>Quality Check Conveyor Solution <span role="img" aria-label="Pin">🔗</span></a></li>
          </ul>
        </div>
      </div>
      <div className="timeline-col-1 timeline-sec-row">
        <p>Timeline</p>
        <div className="timeline-info-div">
          <ul className="timeline">
            <li className="event" data-date="Present">
              <h3>GreyOrange <span>(Senior Engineer)</span></h3>
              <p>- Full-stack Developer</p>
              <p>- Awarded with a <span className="span-color">Spotlight Extra Mile Award</span> for outstanding
                performance for Q3’ FY21-22</p>
            </li>
            <li className="event" data-date="Mar 2021">
              <h3>MedTrail Technologies <span>(Senior Engineer)</span></h3>
              <p>- Led and managed a team of developers, to build solutions around the core product.</p>
              <p>- Architectured, Developed and Scaled solutions for <span className="span-color">Medtrail's Dispensary Product</span>.
              </p>
            </li>
            <li className="event" data-date="Oct 2019">
              <h3>Nagarro Software <span>(Associate Engineer)</span></h3>
              <p>- Core Member of the <span className="span-color">Center of Excellence</span> department which
                specializes in <span className="span-color">IoT</span>.</p>
              <p>- Researched and Developed PoCs & minimum viable products for potential clients in
                various IoT domains like Healthcare, Industrial IoT, Smart cities etc. PoCs were validated by clients
                and solutions were developed as per the requirements.</p>
              <p>- Apart from IoT, developed E2E Web Applications using <span className="span-color">MERN Stack</span>.
              </p>
              <p>- Awarded with an <span className="span-color">Excellence award</span> for outstanding
                performance for the year 2018</p>
            </li>
            <li className="event" id="date" data-date="Jan 2018">
              <h3 style={{marginBottom: "0px"}}>Post Graduation - MCA <span className="span-color">(2015 - 2018)</span></h3>
            </li>
            <li className="event" id="date" data-date="June 2015" >
              <h3 style={{marginBottom: "0px"}}>Graduation - B.Sc.(H) Computer Sc. <span className="span-color">(2012 - 2015)</span></h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
};

export default timeline;

// <Timeline mode="left" style={{color: "var(--secondary-color)"}}>
//     <Timeline.Item label="Present">
//         <Collapse accordion>
//             <Panel header="MedTrail" key="1">
//                 <p className="collapse-text">Working as Full Stack Developer</p>
//             </Panel>
//         </Collapse>
//     </Timeline.Item>
//     <Timeline.Item label="Oct 2019">
//         <Collapse accordion>
//             <Panel header="Nagarro Software"  key="2">
//                 <p className="collapse-text">Working as Full Stack Developer</p>
//             </Panel>
//         </Collapse>
//     </Timeline.Item>
//     <Timeline.Item label="June 2017">
//         <Collapse accordion>
//             <Panel header="Bootcamp - Nagarro Software"  key="2">
//                 <p className="collapse-text">Working as Full Stack Developer</p>
//             </Panel>
//         </Collapse>
//     </Timeline.Item>
//     <Timeline.Item label="July 2016">
//         <Collapse accordion>
//             <Panel header="Symptum - Backend Dev Intern"  key="2">
//                 <p className="collapse-text">Working as Full Stack Developer</p>
//             </Panel>
//         </Collapse>
//     </Timeline.Item>
// </Timeline>
