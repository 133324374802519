import React from 'react';
import {LinkedinOutlined, GithubOutlined, TwitterOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import './AboutMe.css';
import selfImage from '../../../images/self.JPG';
import waveImage from '../../../images/wave-img.png';

const aboutMe = (props) => {
    return (
        <div className="main-body about-body-row animated bounceInUp">
            <div className="aboutme-col-1 about-me-image-sec">
                <p>Hi there, <span className="sunny-span">Sunny</span> this side <img alt="wave"
                                                                                      className="wave-icon animated tada slower delay-1s"
                                                                                      src={waveImage}/></p>
                <img alt="owner pic" className="self-image" src={selfImage}/>
                <div className="about-me-logos">
                <span>
                    <a target="_blank" rel="noopener noreferrer"
                       href="https://twitter.com/sunny_setia_"><TwitterOutlined/></a>
                </span>
                    <span>
                    <a target="_blank" rel="noopener noreferrer"
                       href="https://github.com/sunnysetia93"><GithubOutlined/>
                    </a>
                </span>
                    <span>
                    <a target="_blank" rel="noopener noreferrer"
                       href="https://www.linkedin.com/in/sunnysetia93/"><LinkedinOutlined/>
                    </a>
                </span>
                    <span>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.npmjs.com/package/resume-sunnysetia">
                            <svg viewBox="0 0 780 250" style={{marginLeft: "10px"}}>
                                <path className="npm-svg" fill="var(--secondary-color)"
                                      d="M240,250h100v-50h100V0H240V250z M340,50h50v100h-50V50z M480,0v200h100V50h50v150h50V50h50v150h50V0H480z M0,200h100V50h50v150h50V0H0V200z">

                                </path>
                            </svg>
                    </a>
                </span>
                </div>
                <div style={{alignSelf: "center"}}>
                    <div className="github-card" data-github="sunnysetia93" data-width="300" data-height=""
                         data-theme="default"></div>
                </div>
            </div>
            <div className="aboutme-col-2 about-me-info">
                <p>
                    I like to believe that I am a <span className="span-color">self-taught programmer</span> who loves
                    to explore and learn.
                    Most times, you will find me behind a laptop figuring out solutions to one or the other problem.
                </p>
                <p>
                    Academically speaking, I hold a <span className="span-color">Masters degree</span> in
                    Computer Science and I believe in being language-agnostic. However, at the time of writing this
                    description, I would say I am a bit partial towards <span className="span-color">JavaScript</span>.
                </p>
                <p>
                    I dabble in Computer science, Music, Travel and many other things that sound ambitious
                    and crazy.
                    If you're looking to talk or just share a cup of coffee, please shoot me a message at <span
                    className="span-color" style={{"textDecoration": "underline", "cursor": "pointer"}}
                    onClick={() => props.prodBodyClickHandler(3)}>contact</span>.
                </p>
            </div>
        </div>

    )
};

export default aboutMe;
