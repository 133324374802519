import React, {Component} from 'react';
import {Tabs} from 'antd';
import './Response.css';
import AboutMe from '../MainBody/AboutMe/AboutMe';
import Timeline from '../MainBody/Timeline/Timeline';

const {TabPane} = Tabs;

class Response extends Component {

  getContent = (index) => {
    let mainContent = [
      {
        title: "Hi there, Sunny this side",
        links: {
          twitter: "https://twitter.com/sunny_setia_",
          github: "https://github.com/sunnysetia93",
          linkedIn: "https://www.linkedin.com/in/sunnysetia93/",
          npmResume: "https://www.npmjs.com/package/resume-sunnysetia"
        },
        body: "I like to believe that I am a self-taught programmer who loves to explore and learn. Most times you will find me behind a laptop figuring out solutions to one or the other problem."
          +
          "Apart from being self-taught, I hold a Masters degree in Computer Science. Though I believe to be language-agnostic, at the time of writing this description, I would say I am a bit partial towards JavaScript."
          +
          "I dabble in all things computer science, music, travel and many other things that sound ambitious and crazy. If you're looking to talk or just sharing a cup of coffee, please shoot me a message at contact."
      },
      {
        skills: {
          nodejs: 92,
          reactjs: 81,
          vuejs: 81,
          java: 75,
          pythonDjango: 75,
          elasticSearch: 79,
          SQLDatabases: 82,
          NoSQLDatabases: 80,
          IoT: 65,
          cloudServices: 75,
          DevOps: 70
        },
        timeline: {
          present: {
            heading: "GreyOrange",
            body: `Full-stack development of features for Client Facing Analytics Dashboards. Awarded with an Spotlight Extra Mile Award for outstanding performance for Q3’ FY21-22`
          },
          "mar-2021": {
            heading: "MedTrail Technologies",
            body: `Led and managed a team of developers, to build solutions around the core product. Architectured, Developed and Scaled solutions for Medtrail's Dispensary Product. Wrote modern, performant, and robust code for a diverse array of interlinked products.`
          },
          "oct-2019": {
            heading: "Nagarro Software",
            body: `Core Member of the Center of Excellence department which specializes in IoT. Researched and Developed proof of concepts (PoC) & minimum viable products for potential clients in various IoT domains like Healthcare, Industrial IoT, Smart cities etc. PoCs were validated by clients and solutions were developed as per the requirements.` +
              "Apart from IoT, I was responsible for developing E2E Web Applications using MERN Stack." +
              "I was awarded with an Excellence award for outstanding performance for the year 2018"
          },
          "jan-2018": {
            heading: "Post Graduation - MCA",
            body: "Tenure: 2015 - 2018"
          },
          "june-2015": {
            heading: "Graduation - B.Sc. Computer Sc.",
            body: "Tenure: 2012 - 2015"
          }
        }
      }
    ];
    return mainContent[index];
  };
  getComponent = (index) => {
    let components = [
      <AboutMe prodBodyClickHandler={this.props.prodBodyClickHandler}/>,
      <Timeline/>
    ]
    return components[index];
  }

  render() {
    let content = this.getContent(this.props.content - 1);
    let component = this.getComponent(this.props.content - 1);
    return (
      <div className="response-body">
        <Tabs onChange={this.callback} type="card">
          <TabPane tab="Pretty" key="1">
                        <pre className="prettyprint linenums:4">
                            <code>
                            {JSON.stringify(content, null, 4)}
                            </code>
                        </pre>
          </TabPane>
          <TabPane tab="Raw" key="2">
            {JSON.stringify(content)}
          </TabPane>
          <TabPane tab="Preview" key="3">
            {component}
          </TabPane>
        </Tabs>
      </div>

    )
  }
}

export default Response
