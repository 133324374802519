import React from 'react';
import './LiveRequest.css'
import about_user_img from "../../../icons/about_user_new.png";
import timeline_img from "../../../icons/timeline_new.png";
import talk_img from "../../../icons/talk_new.png";

const liveRequest = (props) => {
    return (
        <React.Fragment>
            <div className="live-col-1 live-request-row animated lightSpeedIn faster">
                <img src={about_user_img} alt="logo" onClick={() => props.prodBodyClickHandler(1)}/>
                <label className="live-label" onClick={() => props.prodBodyClickHandler(1)}>About</label>
            </div>
            <div className="live-col-1 live-request-row animated lightSpeedIn faster">
                <img src={timeline_img} alt="logo" onClick={() => props.prodBodyClickHandler(2)}/>
                <label className="live-label" onClick={() => props.prodBodyClickHandler(2)}>Profile</label>
            </div>
            <div className="live-col-1 live-request-row animated lightSpeedIn faster">
                <img src={talk_img} alt="logo" onClick={() => props.prodBodyClickHandler(3)}/>
                <label className="live-label" onClick={() => props.prodBodyClickHandler(3)}>Let's Talk</label>
            </div>
        </React.Fragment>
    )
}

export default liveRequest;